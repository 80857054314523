/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  () => ({
    highlight: {},
  }),
  { name: 'BackstageHighlightedSearchResultText' },
);

/**
 * Props for {@link HighlightedSearchResultText}.
 *
 * @public
 */
export type HighlightedSearchResultTextProps = {
  text: string;
  preTag: string;
  postTag: string;
};

/**
 * @public
 */
export const HighlightedSearchResultText = (
  props: HighlightedSearchResultTextProps,
) => {
  const { text, preTag, postTag } = props;

  const classes = useStyles();
  const terms = useMemo(
    () => text.split(new RegExp(`(${preTag}.+?${postTag})`)),
    [postTag, preTag, text],
  );

  return (
    <>
      {terms.map((t, idx) =>
        t.includes(preTag) ? (
          <mark className={classes.highlight} key={idx}>
            {t.replace(new RegExp(`${preTag}|${postTag}`, 'g'), '')}
          </mark>
        ) : (
          t
        ),
      )}
    </>
  );
};

/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export const getDashboardParentGuidQuery =
  'query ($query: String) {\n  actor {\n    entitySearch(query: $query) {\n      results {\n        entities {\n          name\n          ... on DashboardEntityOutline {\n            name\n            dashboardParentGuid\n          guid\n          }\n          permalink\n        }\n      }\n    }\n  }\n}\n';

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';
import { ROLLBAR_ANNOTATION } from '../constants';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export function useProjectSlugFromEntity(entity: Entity) {
  const configApi = useApi(configApiRef);

  const [project, organization] = (
    entity?.metadata?.annotations?.[ROLLBAR_ANNOTATION] ?? ''
  )
    .split('/')
    .reverse();

  return {
    project,
    organization:
      organization ??
      configApi.getOptionalString('rollbar.organization') ??
      configApi.getString('organization.name'),
  };
}

/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { devToolsApiRef, DevToolsClient } from './api';

import { rootRouteRef } from './routes';

/** @public */
export const devToolsPlugin = createPlugin({
  id: 'devtools',
  apis: [
    createApiFactory({
      api: devToolsApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new DevToolsClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

/** @public */
export const DevToolsPage = devToolsPlugin.provide(
  createRoutableExtension({
    name: 'DevToolsPage',
    component: () =>
      import('./components/DevToolsPage').then(m => m.DevToolsPage),
    mountPoint: rootRouteRef,
  }),
);

/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';
import { Dag, InstanceStatus, InstanceVersion } from './types';
import { DagRun } from './types/Dags';

export const apacheAirflowApiRef = createApiRef<ApacheAirflowApi>({
  id: 'plugin.apacheairflow.service',
});

export type ApacheAirflowApi = {
  discoveryApi: DiscoveryApi;
  baseUrl: string;
  listDags(options?: { objectsPerRequest: number }): Promise<Dag[]>;
  getDags(dagIds: string[]): Promise<{ dags: Dag[]; dagsNotFound: string[] }>;
  updateDag(dagId: string, isPaused: boolean): Promise<any>;
  getDagRuns(
    dagId: string,
    options?: { objectsPerRequest?: number; limit?: number },
  ): Promise<DagRun[]>;
  getInstanceStatus(): Promise<InstanceStatus>;
  getInstanceVersion(): Promise<InstanceVersion>;
};

/*
 * Copyright 2023 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  EntityRatingsData,
  FeedbackResponse,
  Rating,
  Ratings,
} from '@backstage/plugin-entity-feedback-common';

/**
 * @public
 */
export const entityFeedbackApiRef = createApiRef<EntityFeedbackApi>({
  id: 'plugin.entity-feedback.service',
});

/**
 * @public
 */
export interface EntityFeedbackApi {
  getAllRatings(): Promise<EntityRatingsData[]>;

  getOwnedRatings(ownerRef: string): Promise<EntityRatingsData[]>;

  recordRating(entityRef: string, rating: string): Promise<void>;

  getRatings(entityRef: string): Promise<Omit<Rating, 'entityRef'>[]>;

  /**
   * Returns anonymized aggregated results for one entityRef
   */
  getRatingAggregates(entityRef: string): Promise<Ratings>;

  recordResponse(
    entityRef: string,
    response: Omit<FeedbackResponse, 'entityRef' | 'userRef'>,
  ): Promise<void>;

  getResponses(
    entityRef: string,
  ): Promise<Omit<FeedbackResponse, 'entityRef'>[]>;
}

/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { DateTime, Interval } from 'luxon';
import humanizeDuration from 'humanize-duration';

export function relativeTimeTo(dateTimeISOString?: string) {
  return dateTimeISOString
    ? DateTime.fromISO(dateTimeISOString).toRelative()
    : '';
}

export function durationHumanized(
  startDateTimeISOString?: string,
  endDateTimeISOString?: string,
): string {
  if (!startDateTimeISOString || !endDateTimeISOString) {
    return '';
  }

  const startDateTime = DateTime.fromISO(startDateTimeISOString);
  const endDateTime = DateTime.fromISO(endDateTimeISOString);
  const duration = Interval.fromDateTimes(
    startDateTime,
    endDateTime,
  ).toDuration();

  return humanizeDuration(duration.as('milliseconds'), {
    largest: 1,
  });
}
